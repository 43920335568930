<template>
    <div :class="'m-p profile-container ' + ( this.$store.state.profileSideBar ? 'open' : '' )">
       <AddCompany />
    </div>
 </template>
 <script>
 import { defineAsyncComponent } from 'vue'
 export default {
 name: 'Add Company',
 components: {
   AddCompany: defineAsyncComponent( () => import('@/components/Profile/Companies/AddCompany.vue') ),
 },
 }
 </script>